<template>
  <v-container class="app-page-home mt-n7">
    <!-- HOME CARDS LINKS -->
    <v-row class="mb-sm-3 row-card-link">
      <v-col cols="6" sm="6" md="3" lg="3" class="col-card-link">
        <router-link :to="{ name: 'empresa' }">
          <v-card class="card-link">
            <img class="card-icon" src="@/assets/home/ico-empresa.svg" alt="Icono empresa" />
            <h5 class="card-title text-primary">Empresa</h5>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3" class="col-card-link">
        <router-link :to="{ name: 'liquidaciones' }">
          <v-card class="card-link">
            <img class="card-icon" src="@/assets/home/ico-recibos.svg" alt="Icono recibos" />
            <h5 class="card-title">Recibos</h5>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3" class="col-card-link">
        <router-link :to="{ name: 'dgi' }">
          <v-card class="card-link">
            <img class="card-icon" src="@/assets/home/ico-irpf.svg" alt="Icono IRPF" />
            <h5 class="card-title">IRPF</h5>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3" class="col-card-link">
        <router-link :to="{ name: 'solicitar' }">
          <v-card class="card-link">
            <img class="card-icon" src="@/assets/home/ico-solicitar.svg" alt="Icono Solicitar" />
            <h5 class="card-title">Solicitar</h5>
          </v-card>
        </router-link>
      </v-col>
    </v-row>

    <v-row v-if="has_ext">
      <v-col cols="auto">
        <v-tabs v-model="tab" background-color="transparent" class="tabs-noticias" grow>
          <v-tab class="text-capitalize">Noticias Empresa</v-tab>
          <v-tab class="text-capitalize">Noticias GNS</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="noticias-slide">
          <v-tab-item>
            <v-card flat>
              <news-slider-ext mode="ext" :records="pager_ext.records"></news-slider-ext>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <news-slider mode="gns" :records="pager.records"></news-slider>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else class="mt-sm-3">
      <v-col cols="auto">
        <news-slider mode="gns" :records="pager.records"></news-slider>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogUpdateData" max-width="490">
      <v-card>
        <v-card-title class="headline pb-0">
          <span>Atención</span>
          <v-spacer></v-spacer>
          <v-btn icon right @click="dialogUpdateData = false" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Tienes datos sin verificar. Por tu seguridad, por favor actualiza tus datos.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey" text @click="dialogUpdateData = false">
            Cancelar
          </v-btn>

          <v-btn color="primary" text @click="goTo('perfil')">
            Actualizar Datos
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-container>
</template>

<script>
import NewsSlider from "@/components/noticias/Slider.vue";
import NewsSliderExt from "@/components/noticias/SliderExt.vue";
import { getNovedades } from "@/api/core";
import { mapGetters } from "vuex";
import { hasVerifiedData } from "@/utils/auth";

export default {
  name: "home",
  components: {
    NewsSlider,
    NewsSliderExt,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      tab: null,
      pager: {
        loading: true,
        page: 1,
        records: [],
      },
      pager_ext: {
        loading: true,
        page: 1,
        records: [],
      },
      has_ext: false,
      dialogUpdateData: false
    };
  },

  created() {
    this.has_ext = this.user.company_news != ""
    this.getList()
    this.dialogUpdateData = !hasVerifiedData(this.user)
  },

  watch: {},

  computed: {
    ...mapGetters(["user"]),
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", true);
    this.$store.commit("setActiveNav", 3);
  },

  methods: {
    goTo(name) {
      this.$router.push({
        name: name,
      });
    },

    showNotificaciones() {
      this.$router.push({ name: "notificaciones" });
    },

    async getList() {
      this.pager.loading = true;
      this.pager_ext.loading = true;

      const mode = this.has_ext ? "both" : "gns";
      const data = await getNovedades(this.pager.page, mode);

      if (this.has_ext) {
        if (data.gns && data.gns.articulos) {
          this.pager.records = data.gns.articulos.slice(0, 5);
        }

        if (data.ext) {
          this.pager_ext.records = data.ext.slice(0, 5);
        }
      } else {
        this.pager.records = data.articulos.slice(0, 5);
      }

      this.pager.loading = false;
      this.pager_ext.loading = false;
    }
  },
};
</script>

<style rel="stylesheet/scss"  scope>
/* ---------- CARDS LINKS ---------- */
.card-link {
  height: 130px;
  background: #ffffff;
  padding: 1.2rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  transition: all 200ms ease;
  box-shadow: var(--shadow-1) !important;
  border-radius: var(--border-radius-normal) !important;
}

.card-link:hover {
  transform: var(--scale);
  box-shadow: var(--shadow-1-hover);
}

.card-link img {
  width: 20px;
  height: 30px;
}

.card-link .card-title {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

/* ---------- TABS NOTICIAS ---------- */

.tabs-noticias {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  height: 50px;
}

.tabs-noticias .v-tab {
  margin: 0 1rem;
  background: #ff3301;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  color: white !important;
  padding: 0px;
  border-radius: 1rem;
  padding: 0;
  line-height: 0;
  height: 25px;
  min-width: 150px !important;
  max-width: 150px !important;
  display: flex;
  justify-content: center;
  flex-grow: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  transition: all 300ms;
}

.tabs-noticias .v-slide-group__prev {
  display: none !important;
}

.tabs-noticias .v-tab--active {
  background: #ff3301;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  height: 50px;
  color: white;
  border-radius: 30px 30px 0 0;
  max-width: 200px !important;
}

.v-tabs-slider-wrapper {
  display: none;
}

.v-slide-group__wrapper {
  height: 50px;
}
.noticias-slide {
  background-color: transparent !important;
}

/* ---------- SLIDER NOTICIAS ---------- */

.v-carousel,
.v-window__container {
  border-radius: 1rem;
}
.card-noticia {
  border-radius: 1rem !important;
  height: 100px;
  transition: all 200ms ease;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
}

.card-noticia-img {
  height: 190px;
  padding: 20px 30px;
}
.card-noticia-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 113%;
  color: #f5f7f6;
}
.card-noticia-subtitle {
  padding-top: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #f5f7f6;
}
.card-noticia-category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}
.v-carousel__controls {
  position: relative;
  background: transparent !important;
  bottom: 0px;
}

.noticias-slide .v-card,
.noticias-slide .v-tabs-items {
  background-color: transparent !important;
}

.v-carousel__controls__item {
  transform: scale(0.4);
}
.v-carousel__controls path {
  color: #d8d8d8;
}
.v-carousel__controls .v-btn--active path {
  color: #ff7f04;
}
.v-carousel__controls .v-item--active {
  background-color: #ff7f04;
  transform: scale(0.2);
}

/* @media screen and (max-width: 960px) and (max-height: 820px){
  .card-link{
    height: min-content;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
  }
  .card-link img {margin-right: 1rem;}
} */
</style>


<style>
@media (max-width: 370px) {
  #home-header .nombre-usuario {
    font-size: 90%;
  }
  #home-header2 img {
    height: 70%;
  }
  .card-link {
    height: 110px;
  }
  .row-card-link {
    padding: 0.3rem !important;
  }

  .col-card-link {
    padding: 0.3rem !important;
  }
}
</style>