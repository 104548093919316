<template>
  <v-carousel cycle height="auto" :show-arrows="false">
    <v-carousel-item v-for="(record, i) in records" :key="mode + '-'  + i" :eager="true">
      <v-img 
        @click="navigate(record)"
        class="card-noticia-img white--text align-end"
        :src="record.cabezal"
        width="100%"
        gradient="to bottom left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        style="border-radius:20px"
      >
        <h1 class="card-noticia-title">{{ record.titulo }}</h1>
        <p class="card-noticia-subtitle">{{ record.subtitulo }}</p>
        <v-row>
          <v-col cols="auto" class="pa-0">
            <v-chip
              :class="'ma-2 card-noticia-category color-'+record.color"
              small
              :color="record.color"
              text-color="white"
            >{{ record.categoria }}</v-chip>
          </v-col>
        </v-row>
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>


<script>
export default {
  name: "news-slider",

  props: {
    records: Array,
    mode: String,
  },

  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {
    navigate(record) {
      window.open(record['article-link'], '_blank')
    }
  },
};
</script>

<style>
.v-carousel,
.v-window__container {
  border-radius: 1rem;
}
.card-noticia {
  border-radius: 1rem !important;
  height: 100px;
  transition: all 200ms ease;
  box-shadow: none;
  background: transparent;
}

.card-noticia-img {
  height: 190px;
  padding: 20px 30px;
}
.card-noticia-img:hover {
  cursor: pointer;
}

.card-noticia-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 113%;
  color: #f5f7f6;
}
.card-noticia-subtitle {
  padding-top: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #f5f7f6;
}
.card-noticia-category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}
.v-carousel__controls {
  position: relative;
  background: transparent !important;
  bottom: 0px;
}

.noticias-slide .v-card,
.noticias-slide .v-tabs-items {
  background-color: transparent !important;
}

.v-carousel__controls__item {
  transform: scale(0.4);
}
.v-carousel__controls path {
  color: #d8d8d8;
}
.v-carousel__controls .v-btn--active path {
  color: #ff7f04;
}
.v-carousel__controls .v-item--active {
  background-color: #ff7f04;
  transform: scale(0.2);
}
</style>